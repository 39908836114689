import '../App.css';

function Inventory() {
    return (
        <div> 
            <em>... Coming Soon ...</em>
        </div>
    );
}

export default Inventory;