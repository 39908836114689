import { Card } from 'primereact/card';
import ContactDialog from './contact-dialog';
import { useState } from 'react';
import "./contact.css";

function Contact() {
    const header = (
        <img alt="Card" src={window.location.origin + "/us_small.png"} style={{ opacity: .5 }} />
    );

    const [dialogVisible, setDialogVisible] = useState(false);

    // Hard coding for now because very small project
    const bioDesc = `As President and Co-founder of Wheels for Warriors, I aspire to help and serve as many people as I can. I was born in Lancaster, 
    Pennsylvania to parents who worked blue collar jobs, and did all they could for us. My parents lived paycheck to paycheck, and 
    most of the time it was challenging to pay all the monthly bills. There were many times we relied on donated food and shopped at 
    discount grocery stores. As a result, we moved around a lot and eventually found a semi-permanent home in the West Hempfield area. 
    I graduated from Hempfield High School in the year 2000, and I was the first generation in my family to graduate high school. 
    College was never a thought in my mind, but I was highly considering joining the military. I took two years off to decide what I 
    wanted to do with my life, and from the encouragement of the woman, who is now my wife, I entered Thaddeus Stevens College of 
    Technology in 2002. I graduated in 2004, and it was always my hope that I would someday come back as an instructor. I was highly 
    encouraged by the impact my instructor and mentor, Mr. Dennis Antosy, had on my life and other young people's lives. After graduation, 
    I had a few jobs in the collision industry, but since going into the military was always something I aspired to do, I felt it was time 
    to do what was always on my mind since graduating high school. I enlisted in the United States Army at the age of 28 in the year 2009. 
    I was stationed in Fort Knox, Kentucky in 2010, and for the remainder of my four years of Active Duty. I was deployed to Afghanistan in 
    2011 and returned to Fort Knox for the last year of Active Duty service in 2012. My daughter was born at the end of 2012, and I moved my 
    family back to Pennsylvania.  To finish my military contract, I served two more years in the PA National Guard. A few more jobs in the
    collision industry followed, along with the birth of my son. The opportunity arose with an opening for a Collision Repair Instructor at 
    Thaddeus Stevens College of Technology.  My hope of returning some day became a reality, and I was hired as one of the Collision Repair Instructors 
    in 2020. I pride myself in helping the young people we serve in beginning their careers in collision repair. 
    I am also thankful for the opportunity to be a mentor to some as they navigate the challenges of adulthood. Helping and serving others has always 
    been a passion of mine. Wheels for Warriors is my next big project. It will serve as a vessel in which I can make a mark on more people's lives 
    and serve my community.`;

    return (
        <>
            <div className="flex flex-wrap justify-content-center gap-5">
                <div className='flex'>
                    <Card title="Jason Weiler" subTitle="President" header={header} className="md:w-25rem mt-3 contact-card" onClick={() => setDialogVisible(true)}>
                        <p className="m-0">
                            As President and Co-founder of Wheels for Warriors, I aspire to help and serve as many people as I can. I was born in Lancaster,
                            Pennsylvania to parents who worked blue collar jobs, and did all they could for us...
                            <p><em>Click to read more</em></p>
                        </p>
                    </Card>
                </div>
                <div className='flex'>
                    <Card title="James Geiger" subTitle="Vice President" header={header} className="md:w-25rem mt-3">
                        <p className="m-0">
                            <em>... Insert some background info here ... </em>
                        </p>
                        <p>...</p>
                        <p>...</p>
                    </Card>
                </div>
            </div>
            <ContactDialog name='Jason Weiler' role='President & Co-founder' bio={bioDesc} visible={dialogVisible} onHide={() => setDialogVisible(false)} />
        </>
    );
}

export default Contact;