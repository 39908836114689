import { Dialog } from 'primereact/dialog';

type Props = {
    name: string,
    role: string,
    bio: string,
    visible?: boolean,
    onHide: () => void
}
function ContactDialog(props: Props) {
    const header = (
        <img alt="Card" src={window.location.origin + "/us_small.png"} style={{ opacity: .5 }} />
    );

    return (
        <Dialog visible={props.visible ?? false} header={header} onHide={props.onHide} style={{ width: "80%" }}>
            <b><em>{props.name}</em></b>
            <p>{props.bio}</p>
        </Dialog>
    );
}

export default ContactDialog;