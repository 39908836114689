// Prime React Application wide imports
import "primereact/resources/themes/viva-light/theme.css"
import "../node_modules/primeflex/primeflex.css"
import 'primeicons/primeicons.css';

// React Libraries
import { BrowserRouter, Routes, Route } from "react-router-dom";

// Local
import Navbar from './navbar/navbar';
import GeneralInformation from './general-information/general-information';
import Application from './application/application';
import Inventory from './inventory/inventory';
import Contact from './contact/contact';

function App() {
    return (
        <div className="App">
            <Navbar />
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<GeneralInformation />} />
                    <Route path="/application" element={<Application />} />
                    <Route path="/inventory" element={<Inventory />} />
                    <Route path="/contact" element={<Contact />} />
                </Routes>
            </BrowserRouter>
        </div >
        //#990000 - red
        //#0b5394 - blue
        //
    );
}

export default App;
