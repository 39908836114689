import "./override.css";
import { Menubar } from 'primereact/menubar';

function Navbar() {
    const items = [
        {
            label: 'About',
            url: "/"
        },
        {
            label: 'Contact',
            url: "contact"
        },
        {
            label: 'Inventory',
            url: 'inventory'
        },
        {
            label: 'Application',
            url: '/application'
        }
    ];

    return (
        <div className="grid">
            <div className="col-12 md:col-2 lg:col-2">
                <img alt="NP.Logo" src={window.location.origin + "/logo.jpg"} height={"300px"} style={{ minWidth: "300px" }} />
            </div>
            <div className="col-12 md:col-7 lg:col-7 flex flex-wrap align-items-center">
                <div>
                    <Menubar className="" style={{ backgroundColor: "white", border: 0 }} model={items} />
                    <hr></hr>
                </div>
            </div>
            <div className='md:col-3 lg:col-3 flex flex-wrap align-items-center gap-3'>
                <i className="hidden md:inline lg:inline pi pi-twitter" style={{ fontSize: '2rem', color: '#0b5394' }}></i>
                <i className="hidden md:inline lg:inline pi pi-facebook" style={{ fontSize: '2rem', color: '#0b5394' }}></i>
                <i className="hidden md:inline lg:inline pi pi-instagram" style={{ fontSize: '2rem', color: '#0b5394' }}></i>
            </div>
        </div>
    );
}

export default Navbar;
