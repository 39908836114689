import { Link } from "react-router-dom";

function Application() {
    return (
        <div> 
            <h3>Application Form:</h3>
            <p><Link to={window.location.origin + "/Application.docx"} target="_blank" download>Word Doc</Link></p>
            <p><Link to={window.location.origin + "/Application.pdf"} target="_blank" download>PDF</Link></p>
            <em style={{fontSize:"14px"}}>See bottom of form for submission details</em>
        </div>
    );
}

export default Application;