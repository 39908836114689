import { Panel } from 'primereact/panel';

function GeneralInformation() {
    return (
        <div className="flex flex-wrap justify-content-center">
            <Panel className="flex w-7 mt-5" >
                    <b style={{ color: "#990000" }}>Mission Statement:</b>
                    <p style={{ color: "#0b5394" }}>
                        Helping individuals and families facing transportation challenges,
                        our nonprofit is dedicated to providing reliable vehicle repair services
                        and donating quality vehicles to those in need. Grounded in compassion
                        and community, our mission is to reduce the challenges of transportation,
                        fostering independence and enhancing access to opportunities. Through
                        transparent and ethical practices, we aim to create a sustainable impact,
                        ensuring that every person has the means to reach their destinations and build a brighter
                        future.
                    </p>
            </Panel>
            <Panel className="flex w-7 mt-5">
                    <b style={{ color: "#990000" }}>Vision Statement:</b>
                    <p style={{ color: "#0b5394" }}>
                        To envision a community where every individual, regardless
                        of circumstance, has the freedom to pursue opportunities
                        and fulfill their potential through reliable transportation.
                        Our nonprofit aspires to be the driving force behind a transformative
                        movement, ensuring that no one is hindered by the lack of access to a
                        functional vehicle. By providing essential repair services and
                        facilitating the donation of vehicles, we strive to create a future
                        where mobility is a universal right, fostering empowerment, independence,
                        and a strengthened sense of community.</p>
            </Panel>
        </div>
    );
}

export default GeneralInformation;